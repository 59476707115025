<template>
  <LxpMobileHeader v-if="isMobile">
    <template v-slot:left>
      <div class="util util-back">
        <router-link :to="{name: `${targetName}Sub`, params: {view: view}, query: currentQuery}" class="util-actions util-actions-back">
          <i class="icon-history-back"></i>
        </router-link>
      </div>
    </template>
    <template v-slot:right>
      <div v-if="params.files.length > 0" class="util util-download">
        <a @click.stop="showAttachments = true" class="util-actions util-actions-download">
          첨부파일<i class="icon-download"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-sdl-mento">
    <!-- main-header -->
    <template v-if="!isMobile">
      <div class="main-header">
        <!-- main-header > header-top -->
        <div class="header-top main-component">
          <div class="header-column">
            <router-link :to="{ name: `${targetName}Sub`, params: {view: view}, query: currentQuery }" class="page-nav">
              <i class="icon-kb-nav-arrow"></i>
              <span class="text">이전</span>
            </router-link>
          </div>
        </div>
      </div>
      <!-- //main-header -->
      <!-- main-content -->
      <div class="main-content min-component">
        <div v-if="isReady" class="board-view-container">
          <div class="view-header">
            <h3 class="title">{{ params.title }}</h3>
            <p class="subtitle"><span class="text">{{ timestampToDateFormat(params.regDt, 'yyyy.MM.dd hh:mm')}}작성</span><span class="text">{{ params.lrnerNm }} ・ {{ params.mentor ? '멘토' : '멘티' }}</span></p>
          </div>
          <div class="view-body min-component">
            <div class="view-content">
              <div class="kb-table-template">
                <div class="kb-table kb-table-bordered editor-area ck-editor__editable" v-html="params.cn"></div>
              </div>
            </div>
            <div v-if="params.files.length > 0" class="segment-box my-md-5">
              <strong class="text-lg">첨부파일</strong>
              <ul class="mt-lg-2">
                <li v-for="file in params.files" :key="file.lrnPostFileAtchSn">
                  <a @click.stop="downloadAtch(file)" class="kb-mouse-cursor">
                    <span class="text link-dark">{{file.fileNm}}</span>
                    <span class="text text-black-50 ps-md-3">[{{convertToStorageBytes(file.fileSz)}}]</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="view === 'board' && targetName == 'SSM'" class="like-container">
            <div class="like">
              <img src="@/assets/images/ssl/ic_like.png" alt="좋아요">
              <div>
                <button :class="{'is-active': params.myLikeCnt > 0 }" @click="toggleBoardLike">
                  <span class="me-2">좋아요</span><span class="like-num">{{params.likeCnt}}</span>
                </button>
              </div>
            </div>
          </div>
          <div v-if="session.lrnerId === params.lrnerId && canWrite" class="view-bottom">
            <button class="kb-btn kb-btn-primary" @click="goEdit">
              <span class="text">수정하기</span>
            </button>
          </div>
        </div>
        <BoardComment
            v-if="view === 'board' || targetName == 'SSM'"
            :target-sn="targetSn"
            div="learn"
        />
      </div>
    </template>
    <template v-else>
      <!-- main-content -->
      <div class="main-content min-component">
        <div v-if="isReady" class="board-view-container">
          <div class="view-header">
            <div class="title-wrap">
              <h3 class="title">{{ params.title }}</h3>
              <p class="subtitle">
                <span class="text">{{ timestampToDateFormat(params.regDt, 'yyyy.MM.dd hh:mm')}}작성</span>
                <span class="text">{{ params.lrnerNm }} ・ {{ params.mentor ? '멘토' : '멘티' }}</span>
              </p>
            </div>
          </div>
          <div class="view-body min-component">
            <div class="view-content">
              <div class="kb-table-template">
                <div class="kb-table kb-table-bordered editor-area ck-editor__editable" v-html="params.cn"></div>
              </div>
            </div>
            <div v-if="view === 'board' && targetName == 'SSM'" class="like-container">
              <div class="like">
                <img src="@/assets/images/ssl/ic_like.png" alt="좋아요">
                <div>
                  <button :class="{'is-active': params.myLikeCnt > 0 }" @click="toggleBoardLike">
                    <span class="me-2">좋아요</span><span class="like-num">{{params.likeCnt}}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="session.lrnerId === params.lrnerId && canWrite" class="view-bottom">
            <button class="kb-btn kb-btn-primary kb-btn-w100" @click="goEdit">
              <span class="text">수정하기</span>
            </button>
          </div>
          <BoardComment
              v-if="view === 'board' || targetName == 'SSM'"
              :target-sn="targetSn"
              div="learn"
          />
        </div>
      </div>
    </template>
    <!-- //main-content -->
  </main>

  <!-- 하단 첨부파일 리스트 -->
  <div v-if="isMobile && showAttachments" class="main-content main-component">
    <div class="kb-form-layer is-active">
      <div class="layer-container" @click="showAttachments = false">
        <div class="layer">
          <div class="kb-form-layer-options">
            <div class="layer-option">
              <ul class="layer-option-list">
                <li v-for="file in params.files" :key="file.lrnPostFileAtchSn" class="layer-option-item">
                  <a class="layer-option-link" :title="file.fileNm" @click.stop="downloadAtch(file)">
                    <span class="layer-option-text">{{ file.fileNm != null ? file.fileNm : '-' }}</span>
                    <span class="text-muted">{{ file.fileSz > 0 ? convertToStorageBytes(file.fileSz) : 0 }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {computed, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {convertToStorageBytes, getItem, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/util';
import {
  ACT_DELETE_LRN_BOARD_LK,
  ACT_DOWNLOAD_BOARD_ATCH,
  ACT_GET_LRN_BOARD,
  ACT_GET_LRN_REPORT, ACT_INSERT_LRN_BOARD_LK
} from '@/store/modules/board/board';
import {useAlert} from '@/assets/js/modules/common/alert';
import {grpRoleCdDcds} from '@/assets/js/modules/course/mentoring-common';
import BoardComment from '@/components/board/BoardCommnet';
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";

export default {
  name: 'MentoringView',
  components: {LxpMobileHeader, BoardComment},
  setup() {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showLoading, hideLoading} = useAlert();


    const session = computed(() => store.state.auth.session);
    const view = computed(() => route.params.view);
    const isReady = ref(false);

    const currentQuery = computed(() => route.query);
    const targetSn = computed(() => route.params.targetSn ? parseInt(route.params.targetSn) : 0);
    const targetName = computed(() => {
      if (route.name.indexOf('Mentoring') === 0) {
        return 'Mentoring';
      } else if(route.name.indexOf('SSM') === 0) {
        return 'SSM';
      }
      return 'Mentoring';
    });
    const currentTimestamp = new Date().getTime();
    const canWrite = computed(() => {
      if(params.objBgngDt == null || params.objEndDt == null)
        return true;
      else if(params.objBgngDt <= currentTimestamp && currentTimestamp <= params.objEndDt)
        return true;
      else
        return false;
    });


    const params = reactive({
      lrnPostSn: 0,
      mentor: false,
      title: '',
      cn: '',
      myLikeCnt: 0,
      likeCnt: 0,
      distCrseSn: 0,
      lrnerNm: '',
      lrnerId: '',
      regDt: 0,
      objBgngDt: null,
      objEndDt: null,
      files: [],
    });

    const goEdit = () => {
      router.push({name: `${targetName.value}Write`, params: {distCrseSn: params.distCrseSn, targetSn: targetSn.value, view: view.value}, query: currentQuery.value});
    }

    const showAttachments = ref(false);
    const downloadAtch = (item) => {
      store.dispatch(`board/${ACT_DOWNLOAD_BOARD_ATCH}`, {lrnPostFileAtchSn: item.lrnPostFileAtchSn, fileName: item.fileNm})
    }

    const toggleBoardLike = () => {
      if(params.myLikeCnt > 0){
        store.dispatch(`board/${ACT_DELETE_LRN_BOARD_LK}`, params.lrnPostSn).then(() => {
          params.myLikeCnt = 0;
          params.likeCnt -= 1;
        });
      }else{
        store.dispatch(`board/${ACT_INSERT_LRN_BOARD_LK}`, params.lrnPostSn).then(() => {
          params.myLikeCnt = 1;
          params.likeCnt += 1;
        });
      }
    }


    if (targetSn.value > 0) {
      showLoading();
      if(view.value === 'board' || targetName.value == 'SSM') {
        store.dispatch(`board/${ACT_GET_LRN_BOARD}`, targetSn.value).then(res => {
          if(lengthCheck(res)){
            const item = getItem(res);
            setParams(params, item);
            isReady.value = true;
          }
          hideLoading();
        });
      } else if (view.value === 'report') {
        store.dispatch(`board/${ACT_GET_LRN_REPORT}`, targetSn.value).then(res => {
          if(lengthCheck(res)) {
            const item = getItem(res);
            if(session.value.lrnerId === item.lrnerId) {
              setParams(params, item);
              params.mentor = item.grpRoleCdDcd === grpRoleCdDcds.leader;
              params.title = '종합활동보고서';
              params.cn = item.selfIntro;
              params.regDt = item.mdfcnDt;
            }
            isReady.value = true;
          }
          hideLoading();
        });
      }
    }

    return {
      isReady,
      session,
      targetName,
      canWrite,
      view,
      params,
      targetSn,
      currentQuery,
      showAttachments,
      goEdit,
      downloadAtch,
      toggleBoardLike,
      timestampToDateFormat,
      convertToStorageBytes,
      isMobile: navigationUtils.any(),
    }
  }
};
</script>
