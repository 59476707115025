import {useAlert} from '@/assets/js/modules/common/alert';
import {isSuccess} from '@/assets/js/util';
import store from '@/store';

const {showMessage, showConfirm} = useAlert();


const viewCommentEditConfirm = (edit) => {
  return `댓글을 ${edit}하시겠습니까?`;
}
const viewCommentUpdate = '댓글이 수정되었습니다.';
const viewCommentDelete = '댓글이 삭제되었습니다.';

export const validateComment = (cmnt) => {
  if(!cmnt){
    showMessage('댓글 내용을 입력해주세요.');
    return false;
  }
  return true;
}

const insertComment = (type, params, isLoading, callback) => {
  if(isLoading) return;
  isLoading = true;

  store.dispatch(type, params).then(res => {
    if(isSuccess(res)){
      if(callback instanceof Function) callback(res);
    }
    isLoading = false;
  }).catch(() => {
    isLoading = false;
  });
}

const updateComment = (type, params, isLoading, callback) => {
  if(isLoading) return;
  isLoading = true;
  store.dispatch(type, params).then(res => {
    if(isSuccess(res)){
      showMessage(viewCommentUpdate);
      if(callback instanceof Function) callback(res);
    }
    isLoading = false;
  }).catch(() => {
    isLoading = false;
  });
}

const deleteComment = (type, params, isLoading, items, idx, callback) => {
  if(isLoading) return;
  isLoading = true;
  store.dispatch(type, params).then(res => {
    if(isSuccess(res)){
      showMessage(viewCommentDelete);
      items.splice(idx, 1);
      if(callback instanceof Function) callback(res);
      // callback(res);
    }
    isLoading = false;
  }).catch(() => {
    isLoading = false;
  });
}

export const confirmInsertComment = (type, params, isLoading, comment, callback) => {
  if(validateComment(comment)){
    showConfirm(viewCommentEditConfirm('등록'), () => {insertComment(type, params, isLoading, callback);});
  }
}

export const confirmUpdateComment = (type, params, isLoading, comment, callback) => {
  if(validateComment(comment)){
    showConfirm(viewCommentEditConfirm('수정'), () => {updateComment(type, params, isLoading, callback);})
  }
}

export const confirmDeleteComment = (type, params, isLoading, items, idx, callback) => {
  showConfirm(viewCommentEditConfirm('삭제'), () => {deleteComment(type, params, isLoading, items, idx, callback)});
}
