export const grpRoleCdDcds = {
    leader: '2010001',
    learner: '2010002'
}

export const mentoringBoardTable = '<table><tbody><tr><th><span class="text">모임 일시</span></th><td></td></tr><tr><th><span class="text">구분</span></th><td></td></tr><tr><th><span class="text">활동 주제</span></th><td></td></tr><tr><th><span class="text">배운 점 및 느낀 점</span></th><td></td></tr><tr><th><span class="text">추진 계획</span></th><td></td></tr></tbody></table>';
export const ssmEventBoardTable = '<table><thead><tr><th><span>구분</span></th><th><span>내용</span></th></tr></thead><tbody><tr><td><span>학습구분</span></td><td><span style="color:hsl(0, 0%, 60%);">Running Mate: 동반마케팅/협업마케팅/업무코칭 및 개별학습 중 택1</span></td></tr></tbody></table><br/>';
// const mentorReportTable = '<table><tbody><tr><th><span class="text">주제</span></th><td></td></tr><tr><th><span class="text">멘티 지원 내용</span></th><td></td></tr><tr><th><span class="text">칭찬할 점</span></th><td></td></tr><tr><th><span class="text">개선할 점</span></th><td></td></tr><tr><th><span class="text">성과달성도</span></th><td></td></tr></tbody></table>';
// const menteeReportTable = '<table><tbody><tr><th><span class="text">주제</span></th><td></td></tr><tr><th><span class="text">주요내용</span></th><td></td></tr><tr><th><span class="text">활동결과</span></th><td></td></tr><tr><th><span class="text">소감</span></th><td></td></tr><tr><th><span class="text">성과달성도</span></th><td></td></tr></tbody></table>';
const mentorReportTable = '<table><tbody><tr><th><span class="text">주제</span></th><td><p><span style="color:#999">- 은행내 CDP 수립</span></p></td></tr><tr><th><span class="text">주요내용</span></th><td><p><span style="color:#999">- 은행내 다양한 CDP에 대한 설명을 듣고, 희망 본부부서의 CDP에 대해 자세히 알아봄</span></p></td></tr><tr><th><span class="text">활동결과</span></th><td><p><span style="color:#999">- 202x. xx월 ooo 자격증 취득</span></p></td></tr><tr><th><span class="text">소감</span></th><td><p><span style="color:#999">- 희망 부서에 대한 막연한 생각만 있었으나, 멘토님과 소통하면서 구체적인 업무 범위와 필요역량 등을 듣고 실제적인 준비를 할 수 있게 되었음</span></p><p><span style="color:#999">- 나의 CDP에 대해 다시 한번 계획을 세울 수 있게 됨</span></p></td></tr><tr><th><span class="text">성과달성도</span></th><td><p><span style="color:#999">100%</span></p></td></tr></tbody></table>';
const menteeReportTable = '<table><tbody><tr><th><span class="text">주제</span></th><td><p><span style="color:#999">- 은행내 CDP 수립</span></p></td></tr><tr><th><span class="text">주요내용</span></th><td><p><span style="color:#999">- 은행내 다양한 CDP에 대한 설명을 듣고, 희망 본부부서의 CDP에 대해 자세히 알아봄</span></p></td></tr><tr><th><span class="text">활동결과</span></th><td><p><span style="color:#999">- 202x. xx월 ooo 자격증 취득</span></p></td></tr><tr><th><span class="text">소감</span></th><td><p><span style="color:#999">- 희망 부서에 대한 막연한 생각만 있었으나, 멘토님과 소통하면서 구체적인 업무 범위와 필요역량 등을 듣고 실제적인 준비를 할 수 있게 되었음</span></p><p><span style="color:#999">- 나의 CDP에 대해 다시 한번 계획을 세울 수 있게 됨</span></p></td></tr><tr><th><span class="text">성과달성도</span></th><td><p><span style="color:#999">100%</span></p></td></tr></tbody></table>';

export const getReportTable = (grpRoleCdDcd) => {
    if(grpRoleCdDcd === grpRoleCdDcds.learner) {
        return menteeReportTable;
    } else if(grpRoleCdDcd === grpRoleCdDcds.leader) {
        return mentorReportTable;
    }
    return '';
}

export const mentoringDivText = (learners, lrnerId) => {
    const target = learners.find(x => x.lrnerId === lrnerId);
    if (target) {
        if (target.grpRoleCdDcd === grpRoleCdDcds.leader) {
            return '멘토';
        } else if (target.grpRoleCdDcd === grpRoleCdDcds.learner) {
            return '멘티';
        }
    }
    return '-';
}
