<template>
  <div class="view-comment comment-container">
    <template v-if="!isMobile">
      <div class="comment-write">
        <div class="kb-form-comment">
          <input v-model.trim="comment" type="text" class="kb-form-comment-input" placeholder="댓글 달기" :disabled="disabledComment" @keyup.enter="clickAdd">
          <button class="kb-btn kb-btn-secondary kb-btn-comment" :disabled="disabledComment" @click="clickAdd">
            <span class="text">댓글</span>
          </button>
        </div>
      </div>
      <div class="comment-wrapper">
        <div class="comment-list-top"></div>
        <ul v-if="isReady" class="comment-list">
          <!-- comment-item -->
          <li v-for="(item, index) in items" class="comment-item" :key="index">
            <article class="comment">
              <div class="comment-avatar">
                <div class="avatar">
                  <ProfileImg :target="item"/>
                </div>
              </div>
              <div class="comment-contents">
                <div class="content-metadata">
                  <div class="metadata">{{ item.deptNm }} - {{ item.lrnerNm }}</div>
                  <div class="metadata metadata-date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</div>
                </div>
                <div class="content-text" :class="{'comment-edit-mode': item.editMode}">
                  <template v-if="item.editMode">
                    <div class="comment-form">
                      <div>
                        <input v-model.trim="item.cmnt" type="text" class="comment-field" @keyup.enter="clickChange(index)"/>
                      </div>
                    </div>
                    <div class="comment-actions">
                      <button class="btn" @click="changeMode(index)"><span class="text">취소</span></button>
                      <button class="btn" @click="clickChange(index)"><span class="text">수정</span></button>
                    </div>
                  </template>
                  <p v-else class="text">{{ item.originCmnt }}</p>
                </div>
              </div>

              <div v-if="session.lrnerId === item.lrnerId && !item.editMode" class="comment-actions">
                <div class="dropdown" :class="{'is-active': item.toggle}">
                  <button class="dropdown-btn kb-btn-actions" @click="toggleDropdown(index)"><i class="icon-menu"></i></button>
                  <div class="dropdown-target">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="changeMode(index)"><span class="dropdown-option-text">수정</span></a></li>
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickRemove(index)"><span class="dropdown-option-text">삭제</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </li>
          <!-- comment-item -->
        </ul>
      </div>
    </template>
    <template v-else>
      <div class="comment-write comment-write-divider">
        <div class="kb-form-comment">
          <input v-model.trim="comment" type="text" class="kb-form-comment-input" placeholder="댓글 달기" :disabled="disabledComment" @keyup.enter="clickAdd">
          <button class="kb-btn kb-btn-secondary kb-btn-comment" :disabled="disabledComment" @click="clickAdd">
            <span class="text">댓글</span>
          </button>
        </div>
      </div>
      <div class="comment-wrapper">
        <ul v-if="isReady" class="comment-list">
          <!-- comment-item -->
          <li v-for="(item, index) in items" class="comment-item" :key="index">
            <article class="comment">
              <div class="comment-avatar">
                <div class="avatar">
                  <ProfileImg :target="item"/>
                </div>
              </div>
              <div class="comment-contents">
                <div class="content-text" :class="{'comment-edit-mode': item.editMode}">
                  <template v-if="item.editMode">
                    <div class="comment-form">
                      <div>
                        <input v-model.trim="item.cmnt" type="text" class="comment-field" @keyup.enter="clickChange(index)"/>
                      </div>
                    </div>
                  </template>
                  <p v-else class="text">{{ item.originCmnt }}</p>
                </div>
                <div class="content-metadata">
                  <div class="metadata">{{ item.deptNm }} - {{ item.lrnerNm }}</div>
                  <div class="metadata metadata-date">{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd hh:mm') }}</div>
                </div>
                <div v-if="item.editMode" class="comment-actions">
                  <button class="btn" @click="changeMode(index)"><span class="text">취소</span></button>
                  <button class="btn" @click="clickChange(index)"><span class="text">수정</span></button>
                </div>
              </div>

              <div v-if="session.lrnerId === item.lrnerId && !item.editMode" class="comment-actions">
                <div class="dropdown" :class="{'is-active': item.toggle}">
                  <button class="dropdown-btn kb-btn-actions" @click="toggleDropdown(index)"><i class="icon-menu"></i></button>
                  <div class="dropdown-target">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="changeMode(index)"><span class="dropdown-option-text">수정</span></a></li>
                        <li class="dropdown-option-item"><a href="javascript:" class="dropdown-option-link" @click="clickRemove(index)"><span class="dropdown-option-text">삭제</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </li>
          <!-- comment-item -->
        </ul>
      </div>
    </template>
  </div>


</template>

<script>
import {computed, ref} from 'vue';
import ProfileImg from '@/components/common/ProfileImg';
import {useStore} from 'vuex';
import {timestampToDateFormat} from '@/assets/js/util';
import {
  confirmDeleteComment,
  confirmInsertComment,
  confirmUpdateComment,
} from '@/assets/js/modules/board/comment-common';
import {
  ACT_DELETE_LRN_BOARD_CMNT,
  ACT_GET_LRN_BOARD_CMNT_LIST,
  ACT_INSERT_LRN_BOARD_CMNT,
  ACT_UPDATE_LRN_BOARD_CMNT,
} from '@/store/modules/board/board';
import {getListFunc} from '@/assets/js/ui.init';
import navigationUtils from "@/assets/js/navigationUtils";

export default {
  name: 'BoardComment',
  components: {ProfileImg},
  props: {
    targetSn: {
      type: Number,
      required: true
    },
    div: String, // common, learn
    disabledComment: Boolean
  },
  setup(props) {
    const store = useStore();
    const session = computed(() => store.state.auth.session);

    const isReady = ref(false);
    const items = ref([]);
    const isLoading = ref(false);

    const comment = ref('');

    const toggleDropdown = (index) => {
      items.value.forEach((item, idx) => {
        if(idx === index) {
          item.toggle = !item.toggle;
        } else {
          // 다른 dropdown 은 close
          if(item.toggle) item.toggle = false;
        }
      });
    }

    const divActions = {
      learn : {
        select: `board/${ACT_GET_LRN_BOARD_CMNT_LIST}`,
        add: `board/${ACT_INSERT_LRN_BOARD_CMNT}`,
        change: `board/${ACT_UPDATE_LRN_BOARD_CMNT}`,
        remove: `board/${ACT_DELETE_LRN_BOARD_CMNT}`,
        key: 'lrnPostCmntSn',
        boardKey: 'lrnPostSn'
      }
    }

    const getAction = (act) => {
      if(divActions[props.div]) {
        return divActions[props.div][act] || '';
      }
      return '';
    }

    const clickAdd = () => {
      if(props.disabledComment) return;

      const boardKey= getAction('boardKey');
      const key = getAction('key');

      const params = {params: {cmnt: comment.value}};
      params[boardKey] = props.targetSn;

      confirmInsertComment(getAction('add'), params, isLoading.value, comment.value, (res) => {
        if(res[key] > 0) {
          const pushItem = {
            cmnt: comment.value,
            originCmnt: comment.value,
            regDt: new Date().getTime(),
            lrnerId: session.value.lrnerId,
            lrnerNm: session.value.lrnerNm,
            deptNm: session.value.deptNm
          }
          pushItem[key] = res[key];
          items.value.push(pushItem);
          comment.value = '';
        }
      });
    }

    const changeMode = (idx) => {
      if(!items.value[idx].editMode){
        items.value[idx].cmnt = items.value[idx].originCmnt;
        items.value[idx].toggle = false;
      }
      items.value[idx].editMode = !items.value[idx].editMode;
    }

    const clickChange = (idx) => {
      const key = getAction('key');
      const params = {params: {cmnt: items.value[idx].cmnt}};
      params[key] = items.value[idx][key];
      confirmUpdateComment(getAction('change'), params, isLoading.value, items.value[idx].cmnt, () => {
        items.value[idx].originCmnt = items.value[idx].cmnt;
        items.value[idx].editMode = false;
      });
    }

    const clickRemove = (idx) => {
      items.value[idx].toggle = false;
      const key = getAction('key');
      confirmDeleteComment(getAction('remove'), items.value[idx][key], isLoading.value, items.value, idx);
    }

    const getBoardCommentList = () => {
      getListFunc(getAction('select'), props.targetSn, items, null, () => {
        items.value = items.value.map(x => ({...x, originCmnt: x.cmnt, editMode: false}));
        isReady.value = true;
      });
    }

    if(props.targetSn > 0) {
      getBoardCommentList();
    }



    return {

      session,
      isReady,
      items,
      comment,
      changeMode,
      clickAdd,
      clickChange,
      clickRemove,
      toggleDropdown,
      timestampToDateFormat,
      isMobile: navigationUtils.any(),

    }
  }
};
</script>
